@import "../../../styles/vars";
.prloader__wrapper {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color:$bg-color;
  animation: preloader 1.5s linear;
  opacity: 0;
  pointer-events: none;
}
.prloader__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 95px;
  overflow: hidden;
  animation: preloader-img 1.5s ease-out;
  & img {
      width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 820px){
    width: 450px;
    height: 143px;
  }
  @media screen and (min-width: 1440px){
    width: 600px;
    height: 190px;
  }
  @media screen and (min-width: 1920px){
    width: 900px;
    height: 286px;
  }
}
@keyframes preloader {
  0% {
    opacity: 1;
    pointer-events: auto;
  }
  80% {
    opacity: 1;
    pointer-events: auto;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
    pointer-events: none;
  }
}
@keyframes preloader-img {
  0% {
    opacity: 0;
    transform: translate(-50%, 250%);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, 250%);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

}