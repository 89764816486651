@import "../../../styles/vars";

.footer {
  margin-top: 54px;
  padding: 24px 40px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright__agency, .footer__coordinates {
  display: none;
}

.footer__btn-up {
  border: 1px solid $accent-color;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 820px) {
  .footer {
    margin-top: 0;
    padding-bottom: 28px;
  }
  .footer__container {
  }
  .copyright__agency {
    display: inline-block;
  }
  .footer__btn-up {
    width: 42px;
    height: 42px;

    & img {
      width: 14px;
      height: 14px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer__container {
    padding-left: $margin-left-m;
  }
  .footer__coordinates {
    display: block;
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    padding-bottom: 46px;

  }
  .footer__container {
    padding-left: $margin-left-l;
  }
  .footer__btn-up {
    width: 60px;
    height: 60px;

    & img {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .footer {
    padding-bottom: 62px;
  }
  .footer__container {
    padding-left: $margin-left-xl;
  }
  .footer__btn-up {
    width: 80px;
    height: 80px;

    & img {
      width: 27px;
      height: 27px;
    }
  }
}
