$main-color: #F2F2F2;
$secondary-color: rgba(242, 242, 242, 0.3);
$bg-color: #0F0F0C;
$accent-color: #FCCD03;
$divider-color: #333333;
$error-color: #FF004D;

$margin-left-s: 189px;
$margin-left-m: 302px;
$margin-left-l: 424px;
$margin-left-xl: 564px;


